<template>
  <div class="row px-md-4">
    <div class="row">
      <div class="col-12 col-sm-12">
        <div class="mb-40px mt-14px">
          <router-link to="/settings" class="return-btn"
            ><i class="far fa-arrow-left"></i> Back to all settings</router-link
          >
        </div>
        <h2 class="main-title">
          Automated Report Distribution
        </h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12">
        <div class="card p-60px" ref="card">
          <div class="row" v-if="loading == true">
            <div class="col-12 col-sm-12">
              <grid-loader
                class="expertel-loader"
                color="#ff502d"
                :loading="loading"
                :size="20"
                sizeUnit="px"
              ></grid-loader>
            </div>
          </div>
          <form @submit.prevent="changeConfig()">
            <div class="row mb-4" v-if="loading == false">
              <div
                class="col-12 col-sm-12 col-md-6 col-lg-5 px-4 py-3 d-flex align-items-center justify-content-between bg-gray"
              >
                <h5 class="mb-0 font-circular-book font-weight-normal">
                  Master Report
                </h5>
                <label class="switch">
                  <input
                    type="checkbox"
                    v-model="notification_settings.master"
                  />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div class="row mb-4" v-if="loading == false">
              <div
                class="col-12 col-sm-12 col-md-6 col-lg-5 px-4 py-3 d-flex align-items-center justify-content-between bg-gray"
              >
                <h5 class="mb-0 font-circular-book font-weight-normal">
                  Cost Centre Report
                </h5>
                <label class="switch">
                  <input
                    type="checkbox"
                    v-model="notification_settings.cost_centre_enabled"
                  />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div class="row mb-4" v-if="loading == false">
              <div
                class="col-12 col-sm-12 col-md-6 col-lg-5 px-4 py-3 d-flex align-items-center justify-content-between bg-gray"
              >
                <h5 class="mb-0 font-circular-book font-weight-normal">
                  Manager Report
                </h5>
                <label class="switch">
                  <input
                    type="checkbox"
                    v-model="notification_settings.manager_enabled"
                  />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div class="row mb-4" v-if="loading == false">
              <div
                class="col-12 col-sm-12 col-md-6 col-lg-5 px-4 py-3 d-flex align-items-center justify-content-between bg-gray"
              >
                <h5 class="mb-0 font-circular-book font-weight-normal">
                  User Bills
                </h5>
                <label class="switch">
                  <input
                    type="checkbox"
                    v-model="notification_settings.userbills_enabled"
                  />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div class="row" v-if="loading == false">
              <div class="col-12 col-sm-12 col-md-6 col-lg-5">
                <button class="button-expertel float-right" type="submit">
                  Save
                </button>
                <button
                  class="btn btn-outline-expertel mr-3 float-right rounded-0"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["login_data"],
  data() {
    return {
      height_card: 0,
      loading: true,
      notification_settings: []
    };
  },
  created() {
    if (this.login_data !== undefined || this.login_data.success === false) {
      if (!this.login_data.success) {
        this.$router.push("/");
      } else {
        this.getData();
      }
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    calculateHeight() {
      this.height_card = this.$refs.card.clientHeight;
    },
    getData() {
      const self = this;
      this.loading = true;
      this.axios({
        method: "get",
        url: `https://api.expertel.ca/api/v1/client/me/notification_settings`,
        responseType: "json",
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function(response) {
          self.calculateHeight();
          self.notification_settings = {
            master: response.data.master == 1 ? true : false,
            manager_enabled: response.data.manager_enabled == 1 ? true : false,
            cost_centre_enabled:
              response.data.cost_centre_enabled == 1 ? true : false,
            userbills_enabled:
              response.data.userbills_enabled == 1 ? true : false
          };
          self.loading = false;
        })
        .catch(error => self.makeToast("ERROR", error, "danger"));
    },
    changeConfig() {
      const self = this;
      this.loading = true;
      this.axios({
        method: "post",
        url: `https://api.expertel.ca/api/v1/client/me/notification_settings`,
        responseType: "json",
        data: {
          master: self.notification_settings.master == true ? "1" : "0",
          manager:
            self.notification_settings.manager_enabled == true ? "1" : "0",
          cost_centre:
            self.notification_settings.cost_centre_enabled == true ? "1" : "0",
          userbill:
            self.notification_settings.userbills_enabled == true ? "1" : "0"
        },
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function(response) {
          self.calculateHeight();
          self.notification_settings = {
            master: response.data.master == 1 ? true : false,
            manager_enabled: response.data.manager_enabled == 1 ? true : false,
            cost_centre_enabled:
              response.data.cost_centre_enabled == 1 ? true : false,
            userbills_enabled:
              response.data.userbills_enabled == 1 ? true : false
          };
          self.makeToast(
            "SUCCESS",
            "Notification settings changed successfully",
            "success"
          );
          self.loading = false;
        })
        .catch(error => self.makeToast("ERROR", error, "danger"));
    },
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(`${message}`, {
        title: title,
        autoHideDelay: 5000,
        appendToast: true,
        variant: variant
      });
    }
  }
};
</script>
